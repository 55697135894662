import { Injectable, } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/redux/config-store-state.model';

@Injectable({
  providedIn: 'root'
})
export class AuthorRoleService {

  private roles!: any;

  constructor(private store: Store<AppState>,) {
    this.store.select(state => state.storeDataProvider.roles)
      .subscribe(roles => {
        this.roles = roles;
      });
  }

  buildAuthorsAndRoles(authorsData: any, authorsDetails: any): any[] {

    const autoresArr: any = [];

    if (typeof authorsData === 'undefined' || typeof authorsDetails === 'undefined')
      return autoresArr;

    authorsData.forEach((e: any) => {

      const autor = {
        authorcode: '',
        firstname: '',
        lastname: '',
        role: '',
        priority: 0
      };

      // Info del rol
      if (this.roles) {
        this.roles.some((r: any) => {
          if (e.role == r.rolecode) {
            autor.role =  r.authorrole;
            autor.priority = r.priority
            return true;
          }
          return false;
        });
      } else {
        // Si por algún motivo el dataprovider de Roles no existe, completamos con un rol por default
        autor.role = 'Autor';
        autor.priority = 30;
      }

      // Info del autor
      authorsDetails.some((d: any) => {
        if (e.author == d.authorcode) {
          autor.authorcode = d.authorcode;
          autor.firstname = d.firstname;
          autor.lastname = d.lastname;
          return true;
        }
        return false;
      });

      if (autor.authorcode)
        autoresArr.push(autor);
    });

    return this.sortAuthors(autoresArr);
  }

  getAuthorRole(rolecode: number): string {
    let roleName = '';
    this.roles.forEach((r: any) => {
      if (rolecode == r.rolecode)
        roleName = r.authorrole;
    });
    return roleName;
  }

  // Menor es la prioridad del rol del autor, más importante es el mismo
  sortAuthors(authors: any) {
    if (authors && authors.length > 0) {
      authors.sort((a: any, b: any) => {
        if (a.priority < b.priority) {
          return -1;
        } if (a.priority > b.priority) {
          return 1;
        }
        return 0;
      });
    }
    return authors;
  }

}
